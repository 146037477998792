import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	SaveAction,
	TextFieldWidgetSelectControllerHorizontal,
	TextFieldWidgetText,
	CardHeaderWidget,
	BreadCrumbs,
} from '../../../components';

import {
	Card,
	CardContent,
	// Hidden,
	Typography,
	useTheme,
	Snackbar,
	Alert,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
import viewsTexts from '../../../common/viewsTexts.json';
import { alphabeticalSort, alterArrayGetFields } from '../../../helpers';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Region } from '../OmData/OmData';
import { set } from 'lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.tertiary.main,
		fontWeight: 500,
	},
	divider: {
		background: theme.palette.grey[400],
		marginTop: 10,
	},
	rootHelper: {
		fontSize: 17,
	},
	inputText: {
		fontSize: 26,
	},
}));

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 11 }, (_, index) => currentYear - index);

const OmAdd = (props) => {
	const { userId, userHuerto, userName, userOrchard } = useContext(Context);

	// console.log('----', filterState);

	const axiosContext = useContext(AxiosContext);
	// axiosContext.authAxios //queries with token

	const [loadingQueryState, setLoadingQueryState] = useState(false);
	const [successQueryState, setSuccessQueryState] = useState(false);

	const [loadingClientDataState, setLoadingClientDataState] = useState(false);

	const [orchardsDataComboState, setOrchardsDataComboState] = useState([]);
	const [clientDataState, setClientDataState] = useState([]);
	const [agroBusinessDataState, setAgroBusinessDataState] = useState([]);
	const [comunaState, setComunaState] = useState([]);
	const [provinciaState, setProvinciaState] = useState([]);
	const [regionDataState, setRegionDataState] = useState(
		alphabeticalSort(Region, 'label')
	);
	const [locationDataState, setLocationDataState] = useState([]);
	const [seasonState, setSeasonState] = useState([]);
	const [seasonChecked, setSeasonChecked] = useState(false);
	const [selectedYears, setSelectedYears] = useState([]);

	const [errorState, setErrorState] = useState({ message: '', error: false });

	const [allOrchardsDataState, setAllOrchardsDataState] = useState([]);

	const theme = useTheme();

	// console.log(theme)

	const classes = useStyles();

	// const [valueTabState, setValueTabState] = useState(0);

	// const handleChangeTab = (event, newValue) => {
	// 	setValueTabState(newValue);
	// };

	const {
		handleSubmit,
		control,
		watch,
		reset,
		// setValue,
		getValues,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();

	// const watchRut = watch('rut');
	const watchClient = watch('client');
	const watchAgroBusiness = watch('agroBusinessValue');
	const watchRegion = watch('region');
	const watchProvincia = watch('provincia');
	const watchComuna = watch('comuna');

	const handleYearChange = (year) => (event) => {
		clearErrors('season');
		if (event.target.checked) {
			setSelectedYears([...selectedYears, year]);
		} else {
			setSelectedYears(selectedYears.filter((y) => y !== year));
		}
	};

	const onSubmit = async (values) => {
		console.log(values, '<<<--------');

		setLoadingQueryState(true);
		if (selectedYears.length === 0) {
			setError('season', {
				type: 'manual',
				message: 'Seleccione al menos un año.',
			});
			setLoadingQueryState(false);
			return;
		}
		// saca los objetos
		let clientObj = clientDataState.filter(
			(ele) => ele.value === values.client
		);

		clientObj = alterArrayGetFields(clientObj, ['id', 'label', 'value']);

		let agroBusinessObj = agroBusinessDataState.filter(
			(ele) => ele.value === values.agroBusiness
		);

		agroBusinessObj = alterArrayGetFields(agroBusinessObj, [
			'id',
			'label',
			'value',
		]);

		const agroBusinessArray = values.agroBusiness.map((agro) => {
			const dataAgro = agroBusinessDataState.filter(
				(ele) => ele.value === agro
			);
			// Omitir el campo "id" del objeto
			const { id, ...agroWithoutId } = dataAgro[0];
			return agroWithoutId;
		});

		const selectedComuna = comunaState.find(
			(comuna) => comuna.value === values.comuna
		);
		const comunaLabel = selectedComuna ? selectedComuna.label : '';

		const selectedProvincia = provinciaState.find(
			(provincia) => provincia.value === values.provincia
		);
		const provinciaLabel = selectedProvincia ? selectedProvincia.label : ''; // Obtiene el label de la comuna seleccionada o una cadena vacía si no se encuentra

		const selectedRegion = regionDataState.find(
			(region) => region.value === values.region
		);
		const regionLabel = selectedRegion ? selectedRegion.label : ''; // Obtiene el label de la comuna seleccionada o una cadena vacía si no se encuentra

		// console.log(clientObj, 'clientObj');

		const query = {
			client: clientObj[0].label,
			clientValue: clientObj[0].value,
			agroBusiness: agroBusinessArray,
			label: values.label,
			comuna: comunaLabel,
			provincia: provinciaLabel,
			region: regionLabel,
			comunaValue: values.comuna,
			provinciaValue: values.provincia,
			regionValue: values.region,
			icon: values.icon,
			lat: parseFloat(values.lat),
			lng: parseFloat(values.lng),
			season: selectedYears.map((year) => year.toString()),
		};

		console.log(query, 'query');

		try {
			const response = await axiosContext.authAxios.post(
				'v1/orchard/create-orchard-platform',
				query,
				{
					timeout: 360000, // Aquí puedes ajustar el tiempo de espera en milisegundos (en este ejemplo, 5 segundos)
				}
			);
			const bodyInfo = response.Client;

			console.log(response, '------response');

			if (response.status === 200) {
				if (response?.data?.success) {
					setSuccessQueryState(true);
					reset({
						//agroBusinessValue: '',
						label: '',
						nombre: '',
						comuna: '',
						provincia: '',
						region: '',
						icon: '',
						lat: '',
						lng: '',
						season: [],
					});
					setSelectedYears([]);
				} else {
					setErrorState({
						message: response?.data?.message,
						error: true,
					});
				}
			}
		} catch (err) {
			console.log('Error', err.request.status);
			if (err.request.status === 500) {
				setErrorState({
					message: 'Ocurrió un error en el servidor',
					error: true,
				});
			}
		} finally {
			setLoadingQueryState(false);
		}
	};

	useEffect(() => {
		if (watchClient) {
			const resultClient = clientDataState.filter(
				(ele) => ele.value === watchClient
			);
			setAgroBusinessDataState(
				alphabeticalSort(resultClient[0].agrobusiness, 'label')
			);
		}
	}, [watchClient]);

	useEffect(() => {
		console.log(
			watchClient,
			watchAgroBusiness,
			'-----watchClient, watchAgroBusiness'
		);
		if (watchClient && watchAgroBusiness) {
			const resultOrchards = allOrchardsDataState.filter(
				(ele) =>
					ele.client === watchClient &&
					ele.agroBusinessValue === watchAgroBusiness
			);
			setOrchardsDataComboState(resultOrchards);
		} else {
			setOrchardsDataComboState([]);
		}
	}, [watchClient, watchAgroBusiness, allOrchardsDataState]);

	useEffect(() => {
		if (watchRegion) {
			const resultRegion = regionDataState.filter(
				(ele) => ele.value === watchRegion
			);
			if (resultRegion.length > 0) {
				setProvinciaState(
					alphabeticalSort(resultRegion[0].provincia, 'label')
				);
			} else {
				setProvinciaState([]);
			}
		}
	}, [watchRegion]);

	useEffect(() => {
		if (watchRegion && watchProvincia) {
			const resultProvincia = provinciaState.filter(
				(ele) => ele.value === watchProvincia
			);
			if (resultProvincia.length > 0) {
				setComunaState(
					alphabeticalSort(resultProvincia[0].comuna, 'label')
				);
			} else {
				setComunaState([]);
			}
		} else {
			setComunaState([]);
		}
	}, [watchRegion, watchProvincia]);

	useEffect(() => {
		getClientsAsync();
	}, []);

	const getClientsAsync = useCallback(async () => {
		setLoadingClientDataState(true);
		try {
			let response = await axiosContext.authAxios.post(
				'v1/client/list-clients',
				{
					limit: 500,
				}
			);

			// console.log(response, '<<----respo clientes');

			const { data, status } = response;

			if (status === 200) {
				setClientDataState(alphabeticalSort(data.data, 'label'));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingClientDataState(false);
		}
	}, [axiosContext, setLoadingClientDataState, setClientDataState]);

	const handleCloseSnackBarsComponent = () => {
		setErrorState({ message: '', error: false });
	};

	const onCloseSnack = () => {
		setSuccessQueryState(false);
	};

	return (
		<div className="form-style">
			<BsContainer fluid class="px-0 px-md-3">
				<BsRow class="no-gutters">
					<BsCol class="col-md-12">
						<Card elevation={0}>
							<CardHeaderWidget
								title={viewsTexts.titles.orchard.title}
								subheader={<BreadCrumbs subTitle="" />}
							/>
							{/* <Divider /> */}
							<CardContent>
								<BsRow>
									<BsCol class="col-md-12">
										<form
											autoComplete="on"
											onSubmit={handleSubmit(onSubmit)}
										>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															clientDataState
														}
														titleOption="label"
														control={control}
														name="client"
														labeltext="Cliente"
														errors={errors.client}
														defaultValue=""
														loading={
															loadingClientDataState
														}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															agroBusinessDataState
														}
														titleOption="label"
														control={control}
														name="agroBusiness"
														labeltext="Agrícola"
														errors={
															errors.agroBusiness
														}
														multiple={true}
														defaultValue={[]}
														loading={false}
														req
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="label"
														autoComplete="label"
														labeltext="Huerto"
														//	labeltext={viewsTexts.forms.name}
														req={true}
														defaultValue=""
														control={control}
														errors={errors.label}
														multiple={false}
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={
															regionDataState
														}
														titleOption="label"
														control={control}
														name="region"
														labeltext="Region"
														errors={errors.region}
														defaultValue=""
														loading={false}
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												{/* <TextFieldWidgetText
												name="rut"
												autoComplete="rut"
												labeltext={viewsTexts.forms.rut}
												placeholder="11.111.111-1"
												InputLabelProps={{
													shrink: true,
												}}
												req={true}
												defaultValue=""
												control={control}
												errors={errors.rut}
											/> */}

												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={provinciaState}
														titleOption="label"
														control={control}
														name="provincia"
														labeltext="Provincia"
														errors={
															errors.provincia
														}
														defaultValue=""
														loading={false}
														withDefault
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetSelectControllerHorizontal
														options={comunaState}
														titleOption="label"
														control={control}
														name="comuna"
														labeltext="Comuna"
														errors={errors.comuna}
														defaultValue=""
														loading={false}
														withDefault
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="icon"
														autoComplete="icon"
														labeltext="Icono"
														//	labeltext={viewsTexts.forms.icon} a
														defaultValue=""
														control={control}
														errors={errors.icon}
														multiple={false}
														variant="outlined"
													/>
												</BsCol>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="lat"
														// autoComplete="lat"
														labeltext="Latitud"
														//	labeltext={viewsTexts.forms.lat}
														defaultValue=""
														req={true}
														control={control}
														errors={errors.lat}
														multiple={false}
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-6">
													<TextFieldWidgetText
														name="lng"
														// autoComplete="lng"
														labeltext="Longitud"
														//	labeltext={viewsTexts.forms.lng}
														req={true}
														defaultValue=""
														control={control}
														errors={errors.lng}
														multiple={false}
														variant="outlined"
													/>
												</BsCol>
											</BsRow>
											<BsRow>
												<BsCol class="col-md-12">
													<div
														style={{
															display: 'flex',
															alignItems:
																'center',
														}}
													>
														<div
															style={{
																marginRight:
																	'16px',
																fontSize:
																	'0.85rem',
																width: '165px',
															}}
														>
															Temporada
														</div>
														<div
															style={{
																marginLeft: 10,
															}}
														>
															{years.map(
																(year) => (
																	<FormControlLabel
																		key={
																			year
																		}
																		control={
																			<Checkbox
																				checked={selectedYears.includes(
																					year
																				)}
																				onChange={handleYearChange(
																					year
																				)}
																				color="primary"
																			/>
																		}
																		label={year.toString()}
																	/>
																)
															)}
															{errors.season && (
																<Typography
																	variant="body2"
																	color="error"
																>
																	{
																		errors
																			.season
																			.message
																	}
																</Typography>
															)}
														</div>
													</div>
												</BsCol>
											</BsRow>
											<BsRow class="mt-5">
												<BsCol class="col-md-12 text-center">
													<SaveAction
														title="Guardar"
														color="secondary"
														size="large"
														className="roundedButton ml-md-2"
														variant="contained"
														disabled={
															errorState.error
														}
														messageType="add"
														mutationLoading={
															loadingQueryState
														}
														success={
															successQueryState
														}
														actionClose={
															onCloseSnack
														}
													/>
												</BsCol>
											</BsRow>
										</form>
									</BsCol>
								</BsRow>
								{/* {errorState && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={errorState}
                    onClose={() => setErrorState(false)}
                    autoHideDuration={4500}
                    key={Math.random()}
                  >
                    <SnackBarsComponent
                      variant="error"
                      message="El nombre del huerto ya existe"
                      handleClose={handleCloseSnackBarsComponent}
                    />
                  </Snackbar>
                )} */}
								{errorState.error && (
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										open={errorState}
										onClose={() =>
											setErrorState({
												message: '',
												error: false,
											})
										}
										autoHideDuration={3000}
										key="error-snackbar2"
									>
										<Alert
											onClose={
												handleCloseSnackBarsComponent
											}
											severity="error"
											variant="filled"
											sx={{ width: '100%' }}
										>
											{errorState.message}
										</Alert>
									</Snackbar>
								)}
							</CardContent>
						</Card>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};
export default OmAdd;
